import {
  GUTTERS,
  ImageBreakpoint,
  type ImageColSpan,
  MAX_IMAGE_BREAKPOINT_SIZE,
  NUM_COLUMN,
} from './image.interface';

const IMAGE_MEDIA_QUERIES = {
  SM: `(max-width: ${MAX_IMAGE_BREAKPOINT_SIZE.SM}px)`,
  MD: `(max-width: ${MAX_IMAGE_BREAKPOINT_SIZE.MD}px)`,
  LG: `(max-width: ${MAX_IMAGE_BREAKPOINT_SIZE.LG}px)`,
};

// We need values for all breakpoints (SM to LG). If the user has only passed
// in a subset we will fill out the rest here (inheriting from lower breakpoint).
function getValuesForAllBreakpoints(breakpointValues: ImageColSpan): ImageColSpan {
  const allBreakpointValues: ImageColSpan = { ...breakpointValues };

  Object.values(ImageBreakpoint).forEach((bk, idx, arr) => {
    // Recursively finds a previous breakpoint with a value.
    const assignValToPrevBk = (currentIdx: number) => {
      const prevBk = arr[currentIdx - 1];

      if (prevBk) {
        allBreakpointValues[bk] = allBreakpointValues[prevBk];
      } else {
        assignValToPrevBk(currentIdx - 1);
      }
    };

    if (!allBreakpointValues[bk] && idx > 0) {
      assignValToPrevBk(idx);
    }
  });

  return allBreakpointValues;
}

// Calculate the width based on number of columns and breakpoint.
// Note: this will always use the highest px value for the breakpoint for the calc.
function getSpanWidth(breakpoint: ImageBreakpoint, colSpan: number): number {
  const windowSize = MAX_IMAGE_BREAKPOINT_SIZE[breakpoint];
  const columnCount = NUM_COLUMN[breakpoint];
  const gutterSize = GUTTERS[breakpoint];
  const columnWidth = (windowSize - (columnCount - 1) * gutterSize) / columnCount;
  return Math.ceil(columnWidth * colSpan);
}

// Returns a "sizes" string based on colSpan.
export function getSizesFromSpan(colSpan: ImageColSpan): string {
  if (Object.keys(colSpan).length === 0) {
    return '';
  }

  const allBreakpointSpans = getValuesForAllBreakpoints(colSpan);
  const sizes = Object.values(ImageBreakpoint).map(value => {
    const colSpan = allBreakpointSpans[value] || 0;
    return `${IMAGE_MEDIA_QUERIES[value]} ${getSpanWidth(value, colSpan)}px`;
  });

  return sizes.join(', ');
}
