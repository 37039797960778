export enum ImageOrientation {
  LANDSCAPE,
  PORTRAIT,
}

export enum ImageBreakpoint {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

/* IMPORTANT: these must be kept in sync with the breakpoints in /styles/base/variables.scss */
export const IMAGE_BREAKPOINTS: Record<ImageBreakpoint, number> = {
  SM: 0,
  MD: 768,
  LG: 1280,
};

export const MAX_IMAGE_BREAKPOINT_SIZE: Record<ImageBreakpoint, number> = {
  SM: 767,
  MD: 1279,
  LG: 1919,
};

export const NUM_COLUMN = {
  [ImageBreakpoint.SM]: 5,
  [ImageBreakpoint.MD]: 7,
  [ImageBreakpoint.LG]: 9,
};

export const GUTTERS = {
  [ImageBreakpoint.SM]: 20,
  [ImageBreakpoint.MD]: 48,
  [ImageBreakpoint.LG]: 48,
};

export type ImageColSpan = {
  [key in ImageBreakpoint]?: number;
};

export interface AspectRatio {
  orientation: ImageOrientation;
  ratio: number;
}

export interface IImage {
  className?: string;
  src: string;
  // TODO: this should be required >:|
  alt?: string;
  title?: string;
  description?: string | null;
  aspectRatio?: string;
}
