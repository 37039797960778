import { type FilterRangeInput, type NewsSort, type SearchSort } from '~/v1/_types/globalTypes';
import { type getGrantFilter } from '~/v1/graphql/actions/client/getGrantFilter';
import { type getNewsFilter } from '~/v1/graphql/actions/client/getNewsFilter';
import { type getSearchFilter } from '~/v1/graphql/actions/client/getSearchFilter';
import { type getStaffFilter } from '~/v1/graphql/actions/client/getStaffFilter';

export enum FilterAction {
  ADD,
  REMOVE,
  SET,
}

export enum FilterField {
  ID = 'id',
  NAME = 'name',
}

export enum FilterCategory {
  AREAS = 'grantMakingAreas',
  DEPARTMENTS = 'departments',
  IDEAS = 'ideas',
  CONTENT_TYPES = 'contentTypes',
  YEARS = 'years',
  PAST_PROGRAM = 'pastProgram',
  AMOUNT_RANGES = 'amountRanges',
  YEAR_RANGE = 'yearRange',
  COUNTRY = 'country',
  STATE = 'state',
  TYPES = 'types',
  FEATURES = 'features',
}

export enum FilterQueryProp {
  PARTIAL = 'isInit',
  INITIAL_LIMIT = 'initialLimit',
  LIMIT = 'limit',
  OFFSET = 'offset',
  TERM = 'term',
  SORT = 'sort',
}

export enum FilterSortOption {
  MOST_RELEVANT = 'Most relevant',
  NEWEST = 'Newest',
  OLDEST = 'Oldest',
  A_Z = 'A - Z',
  Z_A = 'Z - A',
}

export type FilterProps = {
  [FilterQueryProp.PARTIAL]: boolean;
  [FilterQueryProp.LIMIT]: number;
  [FilterQueryProp.OFFSET]: number;
  [FilterQueryProp.TERM]: string;
  [FilterQueryProp.SORT]?: SearchSort & NewsSort;
};

export type FilterState = Record<FilterCategory, FilterType[]>;

export type FilterQueryFn =
  | typeof getSearchFilter
  | typeof getStaffFilter
  | typeof getGrantFilter
  | typeof getNewsFilter;

export type FilterFormatFn = (filters: FilterType[]) => string[] | number[];

export type FilterQueryResult = {
  data: object[];
  count: number;
};

export type SortId = SearchSort & NewsSort;

export type SortType = {
  id: SortId;
  name: string;
};

export type FilterType = {
  id: string;
  name: string;
  category: FilterCategory;
  amount?: FilterRangeInput;
  yearRange?: FilterRangeInput;
};

export type FilterUrlType = {
  [FilterField.ID]: string;
  [FilterField.NAME]?: string;
};

export enum FilterGtmType {
  PEOPLE,
  NEWS,
  GLOBAL,
  DATABASE,
}

export type FilterContextType = {
  filterProps: FilterProps;
  filterPills: FilterType[];
  filterData: object[];
  filterCount: number;
  isFilterActive: (filter: FilterType) => boolean;
  isFilterLayoutActive: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  isResetting: boolean;
  setIsFilterLayoutActive: (active: boolean) => void;
  onFilterInit: (data: object[], count: number) => void;
  onFilterAll: () => Promise<void>;
  onFilterRemove: (filter: FilterType) => Promise<void>;
  onFilterUpdate: (filter: FilterType, action: FilterAction) => void;
  onFilterPropagate: (filter: FilterType, action: FilterAction, category: FilterCategory) => void;
  onSortUpdate: (sort: SortType) => void;
  onFilterApply: () => Promise<void>;
  onFilterReset: () => Promise<void>;
  onFilterLoadMore: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FilterConfig = Record<string, any>;
