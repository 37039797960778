import { useState } from 'react';

import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { Image } from '~/v1/components/image/image';
import { ImageBreakpoint } from '~/v1/components/image/image.interface';

import { type NavigationItem } from '../../navigation.interface';
import { type NavigationItemData } from '../secondary.interface';
import styles from '../secondary.module.scss';
import { SecondaryNavigationItems } from '../secondaryNavigationItems';

type SecondaryNavigationContentProps = {
  items: NavigationItemData[] | undefined;
  activeNavigation: NavigationItem | undefined;
  statement: string;
  isDesktop?: boolean;
};

export const SecondaryNavigationContent: React.FC<SecondaryNavigationContentProps> = ({
  items,
  activeNavigation,
  statement,
  isDesktop,
}) => {
  const [activeSecondary, setActiveSecondary] = useState<NavigationItemData | null>(null);
  const statementClasses = cx(styles.statement, 'blockquoteTypography', {
    [styles.statementFaded]: activeSecondary,
  });

  return (
    <div className={styles.contentInner}>
      <div className={styles.itemsWrapper}>
        <SecondaryNavigationItems
          items={items}
          activeNavigation={activeNavigation}
          onMouseEnter={item => setActiveSecondary(item)}
          onMouseLeave={() => setActiveSecondary(null)}
          isDesktop={isDesktop}
        />
      </div>
      <div className={styles.contentRight}>
        <AnimatePresence>
          {activeSecondary && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.3,
                },
              }}
              transition={{ duration: 0.5, ease: [0.12, 0, 0.39, 0] }}
              className={styles.secondaryNavigationItemImage}
            >
              <Image
                src={activeSecondary.image.src}
                alt={activeSecondary.image.title}
                priority
                colSpan={{
                  [ImageBreakpoint.SM]: 4,
                  [ImageBreakpoint.MD]: 7,
                }}
                aspectRatio="2/3"
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {statement && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.3,
                  delay: 0,
                },
              }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}
              className={styles.statementWrapper}
            >
              <p className={statementClasses}>{statement}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
