'use client';

import { Column as GridColumn } from 'light-react-grid';

import { type ColumnProps } from './column.interface';

export const Column: React.FC<ColumnProps> = ({
  children,
  className = '',
  sm,
  md,
  lg,
  offsetLeft = {},
  offsetRight = {},
}) => {
  return (
    <GridColumn
      size={{
        lg,
        md,
        sm,
      }}
      offsetLeft={offsetLeft}
      offsetRight={offsetRight}
      className={className}
    >
      {children}
    </GridColumn>
  );
};
