'use client';

import { forwardRef } from 'react';

import cx from 'classnames';
import { Grid as GridWrapper } from 'light-react-grid';

import styles from './grid.module.scss';

export type GridProps = React.ComponentPropsWithoutRef<'div'> & {
  noMaxWidth?: boolean;
  overflow?: {
    right?: boolean;
    left?: boolean;
  };
};

export const Grid = forwardRef(GridImpl);
function GridImpl(
  { children, className = '', overflow, noMaxWidth, ...props }: GridProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      className={cx(styles.grid, {
        [styles.overflow]: overflow?.right && overflow?.left,
        [styles.right]: overflow?.right,
        [styles.left]: overflow?.left,
        [styles.noMaxWidth]: noMaxWidth,
      })}
      ref={forwardedRef}
      {...props}
    >
      <GridWrapper className={className}>{children}</GridWrapper>
    </div>
  );
}
