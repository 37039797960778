'use client';

import { useBreakpoint } from 'light-react-grid';

import { PrimaryNavigationPlaceholder } from '~/v1/components/navigation/placeholder/placeholder';
import { Breakpoint } from '~/v1/constants/breakpoint';

import styles from './guidelines.module.scss';
import { Column } from '../column/column';
import { Grid } from '../grid';

export const GridGuidelines: React.FC = () => {
  const breakpoint = useBreakpoint();
  const columns =
    breakpoint === Breakpoint.SM
      ? new Array(5)
      : breakpoint === Breakpoint.MD
        ? new Array(7)
        : new Array(9);

  return (
    <div className={styles.gridWrapper}>
      <PrimaryNavigationPlaceholder />
      <Grid>
        {columns.fill('column').map((column, index) => (
          <Column key={`${column}-${index}`} className={styles.column} sm={1} md={1} lg={1} />
        ))}
      </Grid>
    </div>
  );
};
