import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { makeVar } from '@apollo/client';
import FocusTrap from 'focus-trap-react';
import { useBreakpoint } from 'light-react-grid';

import { Breakpoint } from '~/v1/constants/breakpoint';
import { TEST_ID } from '~/v1/constants/testId';
import { useScrollLock } from '~/v1/hooks/useScrollLock';
import { useSticky } from '~/v1/hooks/useSticky';

import { type NavigationData, type NavigationItem } from './navigation.interface';
import styles from './navigation.module.scss';
import { PrimaryNavigation } from './primary/primary';
import { SecondaryNavigation } from './secondary/secondary';

export const SECONDARY_NAVIGATION_ID = 'secondary-navigation';

interface NavigationProps {
  className?: string;
  navigation: NavigationData;
  desktopPrimaryContent?: React.ReactNode;
  showDesktopPrimaryContent?: boolean;
}

export const navigationOpenedVar = makeVar(false);

// most keyboard animations are faster (probably around 400ms), but to be on the safe side we set it to 1s
const KEYBOARD_ANIMATION_DURATION = 1000;

export function Navigation({
  navigation,
  desktopPrimaryContent,
  showDesktopPrimaryContent,
}: NavigationProps) {
  const [activeNavigation, setActiveNavigation] = useState<NavigationItem>();
  const [primaryIsExpanded, setPrimaryIsExpanded] = useState(false);

  useEffect(() => {
    if (activeNavigation) {
      setPrimaryIsExpanded(true);
    }
  }, [activeNavigation]);

  const breakpoint = useBreakpoint();

  const isSecondaryExpanded = !!activeNavigation;

  useEffect(() => {
    navigationOpenedVar(isSecondaryExpanded);
  }, [isSecondaryExpanded]);

  const isSmallOrMedium = breakpoint === Breakpoint.SM || breakpoint === Breakpoint.MD;

  const { ref, isSticky } = useSticky<HTMLDivElement>();
  const shouldLockScroll = isSecondaryExpanded || (isSmallOrMedium && primaryIsExpanded);

  const { ref: scrollRef } = useScrollLock<HTMLDivElement>(
    shouldLockScroll,
    isSecondaryExpanded.toString(),
  );

  const setNavigationPadding = () => {
    if (scrollRef?.current && scrollRef.current.id === SECONDARY_NAVIGATION_ID && visualViewport) {
      scrollRef.current.style.paddingBottom = `${window.innerHeight - visualViewport.height}px`;
    }
  };

  const handleSearchFocusChange = () => {
    setTimeout(setNavigationPadding, KEYBOARD_ANIMATION_DURATION);
  };

  // This enables homepage nav scroll animations
  const router = useRouter();
  useEffect(() => {
    if (router.pathname === '/') {
      document.documentElement.style.setProperty('--scroll-progress', '0');
    } else {
      document.documentElement.style.setProperty('--scroll-progress', '1');
    }
  }, [router.pathname]);

  return (
    // TODO: Maybe create "custom" FocusTrap component (using this one) with option to add aria-hidden to layout.
    //   This could be use for all modal-like components (which are rendered with usePortal) so screen reader behaves same as focus.
    //   In that case we would use usePortal() here
    <FocusTrap
      active={isSecondaryExpanded && !isSmallOrMedium}
      focusTrapOptions={{ initialFocus: false }}
    >
      <div className={styles.navigationWrapper} data-test-id={TEST_ID.NAVIGATION} ref={ref}>
        <PrimaryNavigation
          setActiveNavigation={setActiveNavigation}
          activeNavigation={activeNavigation}
          primaryIsExpanded={primaryIsExpanded}
          setPrimaryIsExpanded={setPrimaryIsExpanded}
          isSticky={isSmallOrMedium && isSticky}
          scrollLockRef={!isSecondaryExpanded ? scrollRef : undefined}
          onSearchClick={handleSearchFocusChange}
          desktopContent={desktopPrimaryContent}
          showDesktopContent={showDesktopPrimaryContent}
        />
        <SecondaryNavigation
          searchSuggestions={navigation.suggestions}
          statement={navigation.babyMissionStatement}
          items={
            activeNavigation && activeNavigation !== 'search'
              ? navigation[activeNavigation]
              : undefined
          }
          setActivePrimaryNavigation={setActiveNavigation}
          activePrimaryNavigation={activeNavigation}
          setPrimaryIsExpanded={setPrimaryIsExpanded}
          scrollLockRef={isSecondaryExpanded ? scrollRef : undefined}
          onSearchFocusChange={handleSearchFocusChange}
        />
      </div>
    </FocusTrap>
  );
}
