export enum QuickLinkContentType {
  ANNUAL_REPORT = 'annualReport',
  ARTICLE = 'article',
  AT_GLANCE_CARD = 'atGlanceCard',
  CALL_FOR_CONCEPT = 'callForConcept',
  CONTACT = 'contact',
  CONTACT_PERSON = 'contactPerson',
  DEPARTMENT = 'department',
  EMPHASIZED_ARTICLE = 'emphasizedArticle',
  EVENTS = 'eventsPage',
  EXTERNAL_EVENT = 'externalEvent',
  EXTERNAL_LINK = 'quickLink',
  EXTERNAL_PERSON = 'externalPerson',
  FAQ = 'faq',
  FINANCIALS = 'financialsPage',
  FOOTER = 'footer',
  GRANT = 'grant',
  GRANT_ACTIVITY_ARTICLES = 'grantActivityArticle',
  GRANT_DETAILS = 'grantDetailsPage',
  GRANT_MAKING_AREA = 'grantmakingArea',
  GRANT_MAKING_AREA_DETAILS = 'grantmakingAreaDetailsPage',
  GRANT_RESOURCES = 'grantResources',
  GRANT_SEARCH_FILTERS = 'grantSearchFilters',
  GRANTMAKING_AREAS = 'grantmakingAreasPage',
  HISTORY = 'historyPage',
  HISTORY_PERSON = 'historyPerson',
  HOME = 'homePage',
  IDEA = 'idea',
  INLINE_IMAGES = 'inlineImages',
  MAIN_NAVIGATION = 'mainNavigation',
  MISSION = 'missionPage',
  MMUF = 'mmuf',
  NEWS = 'newsPage',
  NEWSLETTER = 'newsletterCard',
  OLD_ANNUAL_REPORT = 'oldAnnualReport',
  ORGANIZATION = 'organisation',
  OUTSIDE_NEWS_LINK = 'outsideLink',
  PEOPLE = 'peoplePage',
  PERSON = 'person',
  PRESIDENTIAL_QUOTE_CALL_OUT = 'presidentialQuoteCallOut',
  PRESS_NEWS_ARTICLE = 'pressnewsArticle',
  PRESS_ROOM = 'pressRoom',
  PRIVACY_POLICY = 'privacyPolicy',
  QUICK_LINK = 'connectWithLink',
  QUICK_LINKS_MODULE = 'connectWith',
  QUOTE = 'quote',
  SEARCH_PAGE = 'searchPage',
  SPOTLIGHT_SLIDESHOW = 'spotlightSlideshow',
  SPOTLIGHT_VIDEO = 'spotlightVideo',
  TAXONOMY = 'taxonomy',
  TERMS_OF_USE = 'termsOfUse',
  THEME = 'theme',
  TRUSTEE = 'trustee',
  TRUSTEES = 'trusteesPage',
}

export enum QuickLinkContentSubtype {
  GENERIC = 'Generic',
  GRANTEE = 'Grantee news',
  MELLON_IN_THE_NEWS = 'Mellon news',
  PAST_EVENT = 'Past event',
  REPORT = 'Report',
  VOICES = 'Voices',
}
