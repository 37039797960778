import { type ParsedUrlQuery } from 'querystring';

import { type FilterEvent } from '~/ui/components/analytics';

import {
  FilterAction,
  FilterCategory,
  type FilterState,
  type FilterType,
  type FilterUrlType,
} from './filter.interface';

export const formatState = <T>(filters: FilterState): T => {
  const format = (filterCategory: FilterCategory, filters: FilterType[]) => {
    switch (filterCategory) {
      case FilterCategory.COUNTRY:
      case FilterCategory.STATE:
      case FilterCategory.TYPES:
        return filters.map(({ id }) => id);

      case FilterCategory.YEARS:
        return filters.map(({ id }) => Number(id));

      case FilterCategory.CONTENT_TYPES:
        return filters.map(({ id }) => id.split('___')).flat();

      case FilterCategory.AREAS:
      case FilterCategory.DEPARTMENTS:
      case FilterCategory.FEATURES:
      case FilterCategory.IDEAS:
        return filters.map(({ name }) => name);

      case FilterCategory.AMOUNT_RANGES:
        return filters.map(({ amount }) => ({ gte: amount?.gte, lte: amount?.lte }));

      case FilterCategory.YEAR_RANGE:
        return filters.map(({ yearRange }) => yearRange)[0];

      case FilterCategory.PAST_PROGRAM:
        return Boolean(filters.length);

      default:
        const formattedFilters = filters.map(({ id, name }, index) => {
          return `(id = ${id}, name = ${name})${index < filters.length - 1 ? ', ' : ''}`;
        });
        throw Error(
          `Filter category '${filterCategory}' with filters [${formattedFilters}] is invalid.`,
        );
    }
  };

  return Object.entries(filters).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: format(key as FilterCategory, value),
    };
  }, {} as T);
};

export const updateFilters = (
  [filter, action]: [FilterType, FilterAction],
  state: FilterState,
  propagation?: FilterCategory,
) => {
  const actions = {
    [FilterAction.ADD]: () => ({
      [filter.category]: [...state[filter.category], filter],
    }),
    [FilterAction.REMOVE]: () => ({
      [filter.category]: state[filter.category].filter(({ id }) => filter.id !== id),
    }),
    [FilterAction.SET]: () => ({
      [filter.category]: [filter],
      ...(propagation ? { [propagation]: [] } : {}),
    }),
  };

  return {
    ...state,
    ...actions[action](),
  };
};

export const encodeFilters = (url: string, state: Record<string, FilterUrlType[]>): string => {
  const query = Object.entries(state)
    .map(
      ([category, values]) =>
        `${category}=${values
          .map(({ id, name }) => encodeURIComponent(name ? `${id}:${name}` : id))
          .join(',')}`,
    )
    .join('&');

  return `${url}?${query}`;
};

export const decodeFilters = (
  query: ParsedUrlQuery,
): Record<keyof FilterCategory, FilterUrlType[]> =>
  Object.entries(query)
    .filter(([key]) => Object.values(FilterCategory).includes(key as FilterCategory))
    .reduce(
      (acc, [key, values]) => ({
        ...acc,
        [key]: String(values)
          .split(',')
          .map(value => {
            const [id, name] = decodeURIComponent(value).split(':');

            return {
              id,
              name: name || id,
            };
          }),
      }),
      {} as Record<keyof FilterCategory, FilterUrlType[]>,
    );

export const toFilterState = (
  filters: Record<keyof FilterCategory, FilterUrlType[]>,
): { state: FilterState; pills: FilterType[] } => {
  const state = Object.entries(filters).reduce((acc, [key, value]) => {
    const category = Object.entries(FilterCategory).find(
      ([, categoryValue]) => categoryValue === key,
    );

    const filters = category
      ? value.map(filter => ({
          ...filter,
          category: FilterCategory[category[0] as keyof typeof FilterCategory],
        }))
      : [];

    return {
      ...acc,
      [key]: filters,
    };
  }, {} as FilterState);

  return {
    state,
    pills: Object.values(state).flat(),
  };
};

export const toFilterQuery = (
  field: keyof FilterUrlType,
  filters: Record<keyof FilterCategory, FilterUrlType[]>,
): Record<string, string[]> =>
  Object.entries(filters).reduce((acc, [category, values]) => {
    return {
      ...acc,
      [category]: values.map(value => value[field]),
    };
  }, {});

export const toGtm = (filters: FilterState): FilterEvent => {
  return Object.entries(filters)
    .filter(([, value]) => Boolean(value.length))
    .map(([key, value]) => ({
      [`filterCategory.${key}`]: value.map(({ name }) => name),
    }))
    .reduce(
      (acc, current) => ({
        ...acc,
        ...current,
      }),
      {} as FilterEvent,
    );
};
